import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CorrectionProcessTimelineEventMixtureModel } from '../../models/correction-process-timeline-event-mixture/correction-process-timeline-event-mixture.model';
import { CorrectionProcessTimelineEventMixtureRecipeTypeEnum } from '../../enums/correction-process-timeline-event-mixture-recipe-type/correction-process-timeline-event-mixture-recipe-type.enum';

@Component({
  selector: 'app-select-mixture-type-modal[correctionMixture][novolOrWeightedMixture][canWeightAndAddAsNewEventToTimeline]',
  templateUrl: './select-mixture-type-modal.component.html',
  styleUrl: './select-mixture-type-modal.component.scss',
})
export class SelectMixtureTypeModalComponent {
  @Input() public correctionMixture!: CorrectionProcessTimelineEventMixtureModel;
  @Input() public novolOrWeightedMixture!: CorrectionProcessTimelineEventMixtureModel;
  @Input() public canWeightAndAddAsNewEventToTimeline!: boolean;

  public mixtureTypes: typeof CorrectionProcessTimelineEventMixtureRecipeTypeEnum;

  constructor(private activeModal: NgbActiveModal) {
    this.mixtureTypes = CorrectionProcessTimelineEventMixtureRecipeTypeEnum;
  }

  public close(): void {
    this.activeModal.dismiss();
  }

  public selectMixture(mixture: CorrectionProcessTimelineEventMixtureModel): void {
    this.activeModal.close(mixture);
  }
}
