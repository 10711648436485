<app-modal class="select-mixture-type-modal">
  <span modal-title>{{ 'correctionProcesses.selectMixtureTypeModal.title' | translate }}</span>

  <div modal-content class="content">
    <span *ngIf="!canWeightAndAddAsNewEventToTimeline">
      {{ 'correctionProcesses.selectMixtureTypeModal.content.canNotWeightAndAddAsNewEventToTimeline' | translate }}
    </span>

    <span>{{ 'correctionProcesses.selectMixtureTypeModal.content.selectMixture' | translate }}</span>
  </div>

  <div class="footer" modal-footer>
    <button class="button black-outline" (click)="close()">
      {{ 'correctionProcesses.selectMixtureTypeModal.button.close' | translate }}
    </button>

    <button
      class="button red-outline"
      *ngIf="novolOrWeightedMixture.type === mixtureTypes.novolRecipe"
      (click)="selectMixture(novolOrWeightedMixture)"
    >
      {{ 'correctionProcesses.selectMixtureTypeModal.button.novolMixture' | translate }}
    </button>

    <button
      class="button red-outline"
      *ngIf="novolOrWeightedMixture.type === mixtureTypes.weightedRecipe"
      (click)="selectMixture(novolOrWeightedMixture)"
    >
      {{ 'correctionProcesses.selectMixtureTypeModal.button.weightedMixture' | translate }}
    </button>

    <button class="button red" (click)="selectMixture(correctionMixture)">
      {{ 'correctionProcesses.selectMixtureTypeModal.button.correctionMixture' | translate }}
    </button>
  </div>
</app-modal>
