import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { CustomHttpParamEncoder } from '../../../core/encoders/custom-http-params-encoder/custom-http-params.encoder';
import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { QueryParamsUtil } from '../../../shared/utils/query-params/query-params.util';
import { RecipesListDataPageDto } from '../../dtos/recipes-list-data-page/recipes-list-data-page.dto';
import { RecipeFavoriteDataPageDto } from '../../dtos/recipe-favorite-data-page/recipe-favorite-data-page.dto';
import { RecipeFavoriteListItemDto } from '../../dtos/recipe-favorite-list-item/recipe-favorite-list-item.dto';
import { RecipeCorrectionFormDto } from '../../dtos/recipe-correction-form/recipe-correction-form.dto';
import { RecipeListDto } from '../../dtos/recipe-list/recipe-list.dto';

@Injectable({
  providedIn: 'root',
})
export class RecipesHttpService {
  constructor(private httpClient: HttpClient) {}

  public getRecipesList(recipesListDataPageDto: RecipesListDataPageDto): Observable<RecipeListDto> {
    const params: HttpParams = QueryParamsUtil.mapFiltersToHttpParams<RecipesListDataPageDto>(recipesListDataPageDto);

    return this.httpClient
      .get<ApiResponseDto<RecipeListDto>>(ApiUrlUtil.getApiUrl('recipe/recipes'), { params })
      .pipe(map((response: ApiResponseDto<RecipeListDto>) => response.data));
  }

  public getFavoriteRecipesList(recipeFavoriteDataPage: RecipeFavoriteDataPageDto): Observable<ListDto<RecipeFavoriteListItemDto>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...recipeFavoriteDataPage },
    });

    return this.httpClient
      .get<ApiResponseDto<ListDto<RecipeFavoriteListItemDto>>>(ApiUrlUtil.getApiUrl('recipe/recipes/favorites'), { params })
      .pipe(map((response: ApiResponseDto<ListDto<RecipeFavoriteListItemDto>>) => response.data));
  }

  public addFavorites(formulaId: number, colorId: number): Observable<void> {
    return this.httpClient.post<void>(ApiUrlUtil.getApiUrl(`recipe/recipes/${formulaId}/${colorId}/favorite`), {});
  }

  public deleteFavorites(formulaId: number, colorId: number): Observable<void> {
    return this.httpClient.delete<void>(ApiUrlUtil.getApiUrl(`recipe/recipes/${formulaId}/${colorId}/favorite`));
  }

  public getRecipeCorrectionGroupClipboardMetrics(groupId: string): Observable<string | null> {
    return this.httpClient
      .get<string | null>(ApiUrlUtil.getApiUrl(`recipe/corrections/${groupId}/clipboard-metrics`), {
        observe: 'response',
        responseType: 'text' as 'json',
      })
      .pipe(map((response: HttpResponse<string | null>) => response.body));
  }

  public performRecipeCorrection(recipeCorrectionForm: RecipeCorrectionFormDto): Observable<void> {
    return this.httpClient.post<void>(ApiUrlUtil.getApiUrl('recipe/corrections'), recipeCorrectionForm);
  }
}
